import { AxiosRequestConfig, BaseResponse } from "@/services/types";
import { $api } from "@/services/constant";
import { HOST_API_V1 } from "@/services/env-init";
import { ListJenisTryout } from "./types";

const BASE_URL_V1_PUBLIC = HOST_API_V1 + "public";

export const forgotPassword = async (payload: any) => {
  const res = await $api().put(
    BASE_URL_V1_PUBLIC + "/forgot-password",
    payload
  );
  return res.data;
};

export const updateProfile = async (payload: any) => {
  const res = await $api().put(BASE_URL_V1_PUBLIC + "/profile", payload);
  return res.data;
};

export const resetPassword = async (payload: any) => {
  const res = await $api().put(BASE_URL_V1_PUBLIC + "/reset-password", payload);
  return res.data;
};

export const resetPin = async (payload: any) => {
  const res = await $api().put(BASE_URL_V1_PUBLIC + "/reset-pin", payload);
  return res.data;
};

export const getPackage = async () => {
  const res = await $api().get(BASE_URL_V1_PUBLIC + "/package");
  return res.data;
};

export const getPayment = async () => {
  const res = await $api().get(BASE_URL_V1_PUBLIC + "/payment");
  return res.data;
};

export const getPromo = async (params: AxiosRequestConfig) => {
  const res = await $api().get(BASE_URL_V1_PUBLIC + "/promo", params);
  return res.data;
};

export const getListJenisTryout = async (): Promise<
  BaseResponse<ListJenisTryout[]>
> => {
  const res = await $api().get(BASE_URL_V1_PUBLIC + "/jenis-tryout");
  return res.data;
};
